
*{
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.card-title{
  font-weight: bold;
}

.active_menu {
  font-weight: bold;
  border-bottom: 1 px solid orange;
}


/* New CSS */

/* Colors */
.main-color {
  color: #e84700;
}

/* END-Colors */

/* Animation */

.animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

/* End-Animation */

/* Cards Hover */
.data-cards:hover {
  background-color: #ff9800;
  color: white;
}

/* Counter Widget */
.progress-custom {
  background: white;
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 10px;
  width: auto;
}

.progress-value-custom1 {
  animation: load-1 3s normal forwards;
  box-shadow: 0 10px 40px -10px red;
  border-radius: 100px;
  background: orange;
  height: 5px;
  width: 0;
}

.progress-value-custom2 {
  animation: load-3 3s normal forwards;
  box-shadow: 0 10px 40px -10px red;
  border-radius: 100px;
  background: orange;
  height: 5px;
  width: 0;
}

.progress-value-custom3 {
  animation: load-3 3s normal forwards;
  box-shadow: 0 10px 40px -10px red;
  border-radius: 100px;
  background: orange;
  height: 5px;
  width: 0;
}

@keyframes load-1 {
  0% {
    width: 0;
  }
  100% {
    width: 95%;
  }
}

@keyframes load-2 {
  0% {
    width: 0;
  }
  100% {
    width: 70%;
  }
}

@keyframes load-3 {
  0% {
    width: 0;
  }
  100% {
    width: 70%;
  }
}
/* End-Counter Widget */

/* Counter Box css */
.counter-box {
  display: block;
  background: #f6f6f6;
  padding: 40px 20px 37px;
  text-align: center;
}

.counter-box p {
  margin: 5px 0 0;
  padding: 0;
  color: #909090;
  font-size: 18px;
  font-weight: 500;
}

.counter-box i {
  font-size: 60px;
  margin: 0 0 15px;
  color: #d2d2d2;
}

.counter {
  display: block;
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  line-height: 28px;
}

.counter-box.colored {
  background: #3acf87;
}

.counter-box.colored p,
.counter-box.colored i,
.counter-box.colored .counter {
  color: #fff;
}
/* End Counter Box */

/* Cards */
.our-team {
  padding: 1.2rem 0.75rem;
  background: #fff;
  border-radius: 1rem;
  text-align: center;
  border: 0.5px solid #60c5a8;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.our-team .pic {
  display: inline-block;
  width: 40%;
  height: 40%;
  background: #fff;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  transition: all 0.5s ease 0s;
}
/* .our-team:hover .pic {
  background: #60c5a8;
  border-radius: 20%;
} */
.pic img {
  width: 80%;
  height: auto;
  /* border-radius: 50%; */
}
.our-team .title {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: #60c5a8;
  margin: 0 0 7px 0;
}
.our-team .post {
  display: block;
  font-size: 1rem;
  color: #60c5a8;
  margin-bottom: 1rem;
}
.our-team .social {
  padding: 0;
  margin: 0;
  list-style: none;
}
.our-team .social li {
  display: inline-block;
  margin-right: 5px;
}
.our-team .social li a {
  display: block;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  font-size: 1rem;
  color: #60c5a8;
  border: 1px solid #60c5a8;
  transition: all 0.5s ease 0s;
}
.our-team:hover .social li a {
  background: #60c5a8;
  color: #fff;
}

.social li a:hover {
  background-color: #025784 !important;
  border: none;
  text-decoration: none;
}

@media only screen and (max-width: 992px) {
  .our-team {
    margin-bottom: 2rem;
  }
}

/* End Cards */

/* ABOUT US */

.card {
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  border: 0;
  border-radius: 1rem;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.card h5 {
  overflow: hidden;
  height: 56px;
  font-weight: 900;
  font-size: 1rem;
}
.card-img-top {
  width: 100%;
  max-height: 180px;
  object-fit: contain;
  padding: 30px;
}
.card h2 {
  font-size: 1rem;
}
.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.card:hover img{
  transform: scale(1.09);
}
.label-top {
  position: absolute;
  background-color: #8bc34a;
  color: #fff;
  top: 8px;
  right: 8px;
  padding: 5px 10px 5px 10px;
  font-size: 0.7rem;
  font-weight: 600;
  border-radius: 3px;
  text-transform: uppercase;
}
.top-right {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: 900;
  background: #ff5722;
  line-height: 90px;
  text-align: center;
  color: white;
}
.top-right span {
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 768px) {
  .card-img-top {
    max-height: 250px;
  }
}
.over-bg {
  background: rgba(53, 53, 53, 0.85);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border-radius: 10px;
}


.bg-success {
  font-size: 1rem;
  background-color: #f7810a !important;
}
.bg-danger {
  font-size: 1rem;
}
.price-hp {
  font-size: 1rem;
  font-weight: 600;
  color: darkgray;
}
.amz-hp {
  font-size: 0.7rem;
  font-weight: 600;
  color: darkgray;
}
.fa-question-circle:before {
  color: darkgray;
}
.fa-plus:before {
  color: darkgray;
}
.box {
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.box-img {
  max-width: 300px;
}
.thumb-sec {
  max-width: 300px;
}
@media (max-width: 576px) {
  .box-img {
    max-width: 200px;
  }
  .thumb-sec {
    max-width: 200px;
  }
}
.inner-gallery {
  width: 60px;
  height: 60px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: 1px;
  display: inline-block;
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (max-width: 370px) {
  .box .btn {
    padding: 5px 40px 5px 40px;
    font-size: 1rem;
  }
}
.disclaimer {
  font-size: 0.9rem;
  color: darkgray;
}
.related h3 {
  font-weight: 900;
}

/* Mobile Drawer */
.active{
  color: #e84700;
  font-weight: bold;
  text-decoration: underline;
}
/* End mobile Drawer */

.numberCircle {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  padding: 2px;

  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font: 32px Arial, sans-serif;
}